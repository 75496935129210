$primary: #7b6f67;
$primary-light: #91847c;
$primary-05:rgba(123, 111, 103, .3);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* BOOTSTRAP OVERRIDE */
.btn-primary.focus,
.btn-primary:focus,
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem $primary-05;
}

.btn-primary {
  background-color: $primary;
  border-color:$primary;
  color: white;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: $primary-light;
  border-color:$primary-light;
  color: white;
}

.btn-outline-primary {
  color: $primary;
  border-color: $primary;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

.text-primary {
  color:$primary !important;
}

.form-control::placeholder {
  color:rgb(155, 162, 163);
}
.form-control:focus {
  border-color: $primary-light;
  box-shadow: inset 0 1px 1px rgba(123, 111, 103, 0.075), 0 0 8px $primary-05;
}

.nav.nav-tabs {
  .nav-item {
    > span {
      display:flex;
      align-items:center;
      font-weight:600;
      > img {
        height:16px;
        margin-right:8px;
      }
    }
  }
}

table.table-bordered {
  border:2px solid #DDD;
  th, td {
    padding:10px 16px;
    border:2px solid #DDD;
  }
  th {
    color:#555;
    font-size:14px;
    text-transform:uppercase;
    font-weight:400;
    letter-spacing:1px;
    background-color:#F6F6F6;
  }
  td {
    vertical-align:middle;
  }
}

/* TOASTS */
.Toastify__toast {
  text-align:center;
}


/* LAYOUT */
#full-size-wrapper {
  width:100%;
  height:100vh;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  background-color:#FAFAFA;
}

.card {
  padding:40px;
  .card-body {
    display:flex;
    flex-direction:column;
    align-items:center;
  }
}

img#logo-gestonline {
  height:100px;
  margin-bottom:50px;
}
.home-logo {
  font-family:"Ubuntu";
  font-size:42px;
  line-height:42px;
  letter-spacing:3px;
  margin-bottom:30px;
  padding-bottom:30px;
  color:#000;
  text-transform:uppercase;
  font-weight:500;
  border-bottom:3px solid #CCC;
}
.abcRioButtonBlue { 
  background-color:$primary !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

header {
  display:flex !important;
  justify-content:space-between;
  align-items:center;
  padding:10px;
  background-color:$primary;
  #logo {
    display:flex;
    align-items:center;
    img {
      height:48px;
      margin-right:15px;
      padding-right:15px;
      padding-left:5px;
      border-right:1px solid rgba(255,255,255,.2)
    }
    .name {
      color:#fff;
      text-transform:uppercase;
      font-family:"Ubuntu";
      font-weight:200;
      letter-spacing:2px;
      font-size:24px;
    }
    .utilisateur {
      color:#fff;
      font-family:"Ubuntu";
      font-weight:200;
      letter-spacing:2px;
      font-size:24px;
    }
  }
}

#search-wrapper {
  margin-bottom:20px;
  padding:20px;
  background-color:#F6F6F6;
}

button > svg.svg-inline--fa {
  margin-right:5px;
}

.table td, .table th {
  border-top: none;
}
.table th {
  border-top: none;
  text-transform:uppercase;
  color:#AAA;
  letter-spacing:1px;
  font-size:14px;
  font-weight:500;
}
.table td {
  border-bottom:1px solid #DDD;
  vertical-align:middle;
}

/* FORMS */
.star-required {
  color:red;
}
label {
  margin-top:10px;
}


/* DATEPICKER */
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker {
  .react-datepicker__header {
    background-color: $primary;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color:#fff;
  }
  .react-datepicker__day-name {
    color:#fff;
  }
}